import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import type { ErrorBoundaryComponent } from '@remix-run/react/dist/routeModules'
import { cx } from 'class-variance-authority'
import { useEffect, useState } from 'react'

import { browserLogger } from '~/browser-logger'

export const ErrorBoundaryDisplay: ErrorBoundaryComponent = () => {
  const error = useRouteError()
  browserLogger().error('ErrorBoundaryDisplay', { error })

  const [displayError, setDisplayError] = useState(false)

  useEffect(() => {
    // Set `displayError` to true when `e` key is pressed.
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'e') {
        setDisplayError(!displayError)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  const status = isRouteErrorResponse(error) ? error.status : null
  const message = isRouteErrorResponse(error) ? error.data?.error?.message : 'Well this isn’t right...'

  return (
    <div className='m-6 flex flex-col items-center justify-center rounded bg-white p-4 shadow-elevation1'>
      <div className='flex flex-wrap items-center gap-4'>
        <div>
          <img src='/images/error-ulysses.svg' alt='' />
        </div>

        <div>
          <h1 className='font-brand text-3xl text-error-600'>
            {status && <span className='text-5xl'>{status}</span>}
            {message}
          </h1>
          <p className='max-w-prose'>
            It looks like you’ve caught us in an awkward state—the content for this area won’t load. Try refreshing the page.
          </p>
        </div>
      </div>
      <div
        className={cx('mt-6 text-sm', {
          hidden: !displayError
        })}
      >
        {error instanceof Error ? (
          <>
            <p className='font-semibold'>{error.message}</p>
            <p>The stack trace is:</p>
            <pre className='whitespace-pre-wrap'>{error.stack}</pre>
          </>
        ) : (
          <pre className='whitespace-pre-wrap'>{JSON.stringify(error, null, 2)}</pre>
        )}
      </div>
    </div>
  )
}
